import React, { useMemo } from "react";
import {
  Heading,
  Flex,
  LinkBox,
  Box,
  Image as ChakraImage,
  chakra,
  Avatar,
  Icon,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { Home, MapPin } from "react-feather";
import NextLink from "next/link";
import getConfig from "next/config";
import { useTranslation } from "next-i18next";

import LikeAndShare from "@/components/Garage/LikeAndShare";
import { getDistanceLabel } from "@/utils/distance";
import useCurrentLocation from "@/hooks/useCurrentLocation";
import { Garage } from "@/types/user";
import ShareModal from "@/components/common/ShareModal";
import checkIfFullFridge from "@/utils/fullFridge";

const { publicRuntimeConfig } = getConfig();

type Props = {
  garage: Garage;
  refreshGarages: () => void;
};

const GarageCard = ({ garage, refreshGarages }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { location } = useCurrentLocation();
  const slicedProducts = useMemo(() => garage?.products?.slice(0, 6), [garage]);
  const shareModal = useDisclosure();
  const isFullFridge = checkIfFullFridge();

  return (
    <>
      <Box px="10px">
        <Box
          maxW="300px"
          mx="auto"
          borderRadius="0px"
          position="relative"
          overflow="hidden"
          borderWidth="0px"
          borderColor="green.500"
        >
          <NextLink href={`/g/${garage?.slug}`} passHref>
            <LinkBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              position="relative"
              h="300px"
            >
              <Flex
                flex={1}
                flexDirection="row"
                flexWrap="wrap"
                alignItems="flex-start"
                justifyContent="center"
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
              >
                {slicedProducts?.map((product, index) => {
                  let imageHeight = "100%";
                  let imageWidth = "100%";

                  if (slicedProducts?.length >= 2) imageHeight = "50%";

                  if (slicedProducts?.length === 2) {
                    imageWidth = "100%";
                  } else imageWidth = "50%";

                  if (slicedProducts?.length > 4) imageHeight = "35%";

                  if (slicedProducts?.length === 1) {
                    imageHeight = "100%";
                    imageWidth = "100%";
                  }

                  if (
                    slicedProducts?.length % 2 === 1 &&
                    slicedProducts?.length - 1 === index
                  )
                    imageWidth = "100%";

                  return (
                    <ChakraImage
                      key={`${product?.name}_${product?.id}`}
                      src={
                        product?.defaultPhoto
                          ? `${publicRuntimeConfig.basePath}/${product?.defaultPhoto}.png`
                          : `${publicRuntimeConfig.apiUrl}/uploads/${product?.photos?.[0]?.filename}`
                      }
                      alt=""
                      w={imageWidth}
                      h={imageHeight}
                      objectFit="cover"
                    />
                  );
                })}
              </Flex>

              <Box bg="#e8e8e8" p="5px 10px" position="relative">
                <Flex alignItems="center">
                  <Avatar
                    bg="red.500"
                    src={
                      garage?.user?.profile?.picture
                        ? `${publicRuntimeConfig.apiUrl}/uploads/${garage?.user?.profile?.picture}`
                        : ""
                    }
                    icon={<Icon as={Home} color="white" w={5} h={5} />}
                    mr="5px"
                    size={"sm"}
                  />
                  <Heading
                    size="sm"
                    fontSize="15px"
                    variant="blue"
                    mt={0}
                    mb={0}
                    textAlign="left"
                    flex={1}
                    noOfLines={1}
                  >
                    {garage?.name}
                  </Heading>
                  <Tooltip
                    placement="top"
                    label={t(
                      isFullFridge
                        ? "productsCountInFridge"
                        : "productsCountInGarage"
                    )}
                  >
                    <Box bgColor={"white"} rounded="md" p={1} pl={2} pr={2}>
                      <chakra.b fontSize="15px" color="green.500">
                        {garage?.products?.length}
                      </chakra.b>
                    </Box>
                  </Tooltip>
                </Flex>
              </Box>

              {location?.lat && (
                <Flex
                  fontWeight="bold"
                  position="absolute"
                  top="5px"
                  left="5px"
                  bg="white"
                  lineHeight={1}
                  p="5px 7.5px"
                  align="center"
                  borderRadius="5px"
                  color="green.500"
                  fontSize="11px"
                >
                  <Icon w="10px" h="10px" mr="5px" as={MapPin} />
                  {t("kilometersFromYouShort", {
                    distance: getDistanceLabel(t, garage?.distance, false),
                  })}
                </Flex>
              )}
            </LinkBox>
          </NextLink>

          <Flex position="absolute" right="5px" top="5px" zIndex={99}>
            <LikeAndShare
              garageId={garage?.id}
              isProduct={false}
              favourite={garage?.liked}
              garageRefresher={refreshGarages}
              isUserGarage={false}
              w={"25px"}
              h={"25px"}
              onShare={shareModal.onOpen}
            />
          </Flex>
        </Box>
      </Box>

      {shareModal.isOpen && (
        <ShareModal
          isOpen={shareModal.isOpen}
          onClose={shareModal.onClose}
          url={`/g/${garage?.slug}`}
        />
      )}
    </>
  );
};

export default GarageCard;
